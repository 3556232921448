import type { FunctionComponent } from 'react';
import { useEffect } from 'react';

import { Title, Text } from '@andes/typography';

import type { Metrics } from '../../../utils/contracts';
import { useWordings } from '../../hooks/useWordings';
import { sendMelidata } from '../../utils/sendMelidata';
import { TrackPaths } from '../../../utils/constants';

type TitleProps = {
  currentUrl: string;
  metrics: Metrics;
  type: string;
  listName: string;
};

export const TitleList: FunctionComponent<TitleProps> = (props) => {
  const { currentUrl, metrics, type, listName } = props;
  const { NAVIGATE_TO_LIST, MY_RECOMMENDATIONS } = useWordings();

  useEffect(() => {
    const fragment = window.location.hash;
    const params = new URLSearchParams(fragment.slice(1));
    const trackingId = params.get('tracking_id');

    if (trackingId && typeof trackingId === 'string') {
      metrics.tracking_id = trackingId;
    }
  }, [metrics]);

  const handleOnClick = () => {
    const {
      owner_id: ownerId,
      total_results: totalResults,
      ...restMetrics
    } = metrics;

    sendMelidata('cleanAndSend', 'event', TrackPaths.ACTIONS, {
      ...restMetrics,
      action_type: 'click',
      action_section: 'navigate_to_list',
    });
  };

  return (
    <div className="title-list_container">
      <Title component="h1" size="m">
        {type === 'DEFAULT' ? MY_RECOMMENDATIONS : listName}
      </Title>
      <Text
        onClick={() => handleOnClick()}
        component="a"
        color="link"
        href={currentUrl}
        weight="semibold"
      >
        {NAVIGATE_TO_LIST}
      </Text>
    </div>
  );
};
