import type { ActionConfigType } from '@andes/snackbar';

import { Component } from 'react';

import { Snackbar as AndesSnackbar } from '@andes/snackbar';

interface SnackbarState {
  show: boolean;
  delay: number;
  message: string;
  color?: 'green' | 'red';
  actionMessage?: string;
  onActionClick?: (() => void) | null;
  className: string;
  action?: ActionConfigType;
}

class Snackbar extends Component<object, SnackbarState> {
  static singletonInstance: Snackbar | null;

  static show(options: any) {
    if (Snackbar.singletonInstance) {
      Snackbar.showSnack(options);
    } else {
      console.error(
        'Missing snackbar instance. A Snackbar component must be rendered in the page',
      );
    }
  }

  static hide(e: Event) {
    if (Snackbar.singletonInstance) {
      Snackbar.singletonInstance.hide(e);
    } else {
      console.error(
        'Missing snackbar instance. A Snackbar component must be rendered in the page',
      );
    }
  }

  static updateState(snackbarShowing: any) {
    if (Snackbar.singletonInstance) {
      Snackbar.singletonInstance.updateState(snackbarShowing);
    } else {
      console.error(
        'Missing snackbar instance. A Snackbar component must be rendered in the page',
      );
    }
  }

  static showSnack(snackbarOptions: any) {
    snackbarOptions.show = true;
    snackbarOptions.action = {
      text: snackbarOptions.actionMessage || '',
      onClick: (e: Event) => Snackbar.singletonInstance?.onActionClick(e),
    };
    snackbarOptions.delay =
      snackbarOptions.delay === undefined ? 5000 : snackbarOptions.delay;
    snackbarOptions.className = snackbarOptions.className || '';

    if (Snackbar.singletonInstance) {
      Snackbar.singletonInstance.setState(snackbarOptions);
    }
  }

  constructor(props: object) {
    super(props);
    this.state = {
      show: false,
      delay: 5000,
      message: '',
      color: undefined,
      actionMessage: undefined,
      onActionClick: null,
      className: '',
    };
    this.updateState = this.updateState.bind(this);
    this.hide = this.hide.bind(this);
    this.onActionClick = this.onActionClick.bind(this);
    Snackbar.singletonInstance = this;
  }

  onActionClick(e: Event) {
    const { actionMessage, onActionClick } = this.state;

    this.hide(e);

    if (actionMessage && onActionClick) {
      onActionClick();
    }
  }

  updateState = (snackbarShowing: boolean) => {
    this.setState({ show: snackbarShowing });
  };

  hide(e: Event) {
    e.preventDefault();
    this.setState({ show: false });
  }

  render() {
    const { show, message, delay, color, action, className } = this.state;

    return Snackbar.singletonInstance === this ? (
      <AndesSnackbar
        show={show}
        message={message}
        delay={delay}
        color={color}
        onChange={this.updateState}
        action={action}
        className={className}
      />
    ) : null;
  }
}

export { Snackbar };
