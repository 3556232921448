import type { PolycardContractInterface } from '@polycard/card';

import { useContext, useState } from 'react';

import { sendMelidata } from '../utils/sendMelidata';
import { TrackPaths } from '../../utils/constants';
import { useRequestContent } from './useRequestContent';
import { useSnackbar } from './useSnackbar';
import { useWordings } from './useWordings';
import AppContext from '../providers/AppProvider';
import { usePagination } from '../providers/Pagination';

interface DeleteItemResponse {
  handleDelete: (event: React.MouseEvent<HTMLButtonElement>, id: any) => void;
  deleteProductList: (elementId?: PolycardContractInterface) => void;
  removeItemFromDeleted: (id: string) => void;
  itemToRemove: PolycardContractInterface | undefined;
  setItemToRemove: React.Dispatch<
    React.SetStateAction<PolycardContractInterface | undefined>
  >;
  polycardDeleted: Array<string>;
}

interface DeleteItemProps {
  setPolycardsState: React.Dispatch<
    React.SetStateAction<Array<PolycardContractInterface>>
  >;
  polycardsState: Array<PolycardContractInterface>;
  listId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  elementType: string;
  setElementType: React.Dispatch<React.SetStateAction<string>>;
}

export const useDeleteItem = ({
  setPolycardsState,
  listId,
  polycardsState,
  setOpen,
  elementType,
  setElementType,
}: DeleteItemProps): DeleteItemResponse => {
  const [polycardDeleted, setPolycardDeleted] = useState<Array<string>>([]);
  const { env } = useContext(AppContext);
  const [itemToRemove, setItemToRemove] = useState<PolycardContractInterface>();
  const { showSnackbar } = useSnackbar();
  const { SNACKBAR_ERROR_DELETE, SNACKBAR_SUCCESS_DELETE } = useWordings();
  const { deleteCacheResponse } = usePagination();

  const removeItemFromDeleted = (id: string) =>
    setPolycardDeleted(polycardDeleted.filter((item) => item !== id));

  const onError = () => {
    showSnackbar(SNACKBAR_ERROR_DELETE);

    if (itemToRemove) {
      removeItemFromDeleted(itemToRemove?.metadata.id);
    }

    setItemToRemove(undefined);
  };

  const onSuccess = () => {
    showSnackbar(SNACKBAR_SUCCESS_DELETE, 'green');

    if (itemToRemove) {
      const id = itemToRemove?.metadata.id;

      sendMelidata('cleanAndSend', 'event', TrackPaths.DELETE, {
        element_id: id,
        element_type: elementType,
      });

      removeItemFromDeleted(id);

      setItemToRemove(undefined);

      setPolycardsState((prevPolycards) =>
        prevPolycards.filter((item) => item.metadata.id !== id),
      );
      deleteCacheResponse();
    }
  };

  const { deleteProduct } = useRequestContent({ onError, onSuccess });

  const handleDelete = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any,
  ) => {
    event.preventDefault();

    const deletedItem = polycardsState.find((item) => item.metadata.id === id);

    const isDeleted = polycardDeleted.includes(id);

    if (!deletedItem || isDeleted) {
      return;
    }

    const deletedItemType =
      'product_id' in deletedItem.metadata && deletedItem.metadata.product_id
        ? 'product'
        : 'item';

    setElementType(deletedItemType);

    setItemToRemove(deletedItem);
    setPolycardDeleted([...polycardDeleted, id]);
    setOpen(true);
  };

  const deleteProductList = (item?: any) => {
    if (item) {
      const elementId = item.metadata.product_id || item.metadata.id;
      const bookmarkId = item.metadata.bookmark_id || undefined;

      deleteProduct({ elementId, env, listId, bookmarkId });
    }
  };

  return {
    handleDelete,
    deleteProductList,
    removeItemFromDeleted,
    itemToRemove,
    setItemToRemove,
    polycardDeleted,
  };
};
