import type { PolycardContractInterface } from '@polycard/card';
import type { Metrics } from '../../../../utils/contracts';
import type { DeviceType } from '../../../utils/utils';

import PolycardItem from '../src/PolycardItem';

interface GridProps {
  type: 'carousel' | 'grid';
  policards: Array<PolycardContractInterface>;
  metrics: Metrics;
  polycardDeleted?: Array<string>;
  deviceType?: DeviceType;
}

const Grid = ({
  type,
  policards,
  metrics,
  polycardDeleted,
  deviceType,
}: GridProps) => {
  if (type !== 'grid' || policards.length === 0) {
    return null;
  }

  return (
    <>
      {policards.map((polycard: PolycardContractInterface, i: number) => (
        <PolycardItem
          key={`${polycard?.metadata?.id}-${i + 1}`}
          polycard={polycard}
          metrics={metrics}
          className="lists-card"
          polycardDeleted={polycardDeleted}
          deviceType={deviceType}
        />
      ))}
    </>
  );
};

export default Grid;
