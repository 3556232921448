import type { MeliUser, RequestAuthInfo } from 'nordic/auth';
import type { HeaderData } from '../../utils/contracts';

const IS_AFFILIATE = 'is_affiliate';
const INTERNAL_TAGS = 'internal_tags';

export const ERROR = {
  REQUEST: 'REQUEST',
  TYPE_ERROR: 'TYPE_ERROR',
  RANGE_ERROR: 'RANGE_ERROR',
  REFERENCE_ERROR: 'REFERENCE_ERROR',
  SYNTAX_ERROR: 'SYNTAX_ERROR',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  URI_ERROR: 'URI_ERROR',
  EVAL_ERROR: 'EVAL_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

export const isJsError = (err: unknown) =>
  err instanceof TypeError ||
  err instanceof RangeError ||
  err instanceof ReferenceError ||
  err instanceof SyntaxError ||
  err instanceof URIError ||
  err instanceof EvalError;

const getTypeError = (err: unknown) => {
  switch (true) {
    case err instanceof TypeError:
      return ERROR.TYPE_ERROR;
    case err instanceof RangeError:
      return ERROR.RANGE_ERROR;
    case err instanceof ReferenceError:
      return ERROR.REFERENCE_ERROR;
    case err instanceof SyntaxError:
      return ERROR.SYNTAX_ERROR;
    case err instanceof URIError:
      return ERROR.URI_ERROR;
    case err instanceof EvalError:
      return ERROR.EVAL_ERROR;

    default:
      return ERROR.UNKNOWN_ERROR;
  }
};

export const mapperTypeError = (err: any) => ({
  type: getTypeError(err),
  message: err?.message,
  error: err.stack,
});

export const mapperErrorRequest = (err: any) => ({
  type: ERROR.REQUEST,
  message: `url: ${err.response?.config?.url} - message: ${JSON.stringify(
    err.response?.data?.message ?? err.response?.data,
  )}`,
  error: err.response?.data?.error || err.response?.statusText,
  status: err.response?.status ?? err.code,
});

export const serverErrorHandler = (err: any) => {
  const typeError = isJsError(err);
  const result = typeError ? mapperTypeError(err) : mapperErrorRequest(err);

  return result;
};

export function isMeliUser(user: any): user is MeliUser {
  return (
    typeof user === 'object' &&
    user !== null &&
    'owner' in user &&
    typeof user.owner === 'boolean' &&
    'raw' in user &&
    typeof user.raw === 'object' &&
    INTERNAL_TAGS in user.raw &&
    (user.raw.internal_tags === undefined ||
      Array.isArray(user.raw.internal_tags))
  );
}

export const isAffiliateUser = (auth: RequestAuthInfo): boolean =>
  auth &&
  auth.user &&
  isMeliUser(auth.user) &&
  auth.user.owner &&
  auth.user.raw.internal_tags?.includes(IS_AFFILIATE) === true;

export const isOwnerUser = (
  ownerId: string | undefined,
  userId: string | undefined,
): boolean =>
  ownerId !== undefined && userId !== undefined && ownerId === userId;

const CardType = {
  GRID: 'grid-card',
  LIST: 'list',
} as const;

export function getCardType(deviceType: string, type?: string) {
  return deviceType === 'desktop' || type === 'carousel'
    ? CardType.GRID
    : CardType.LIST;
}

export const getHeaderWithBase = (
  headerResponse: Record<string, unknown>,
  domain: string,
  buyerId?: string,
) => {
  const headerProps = {
    ...headerResponse,
    baseURL: `${domain}/social/api/lists/follow`,
    buyer_id: buyerId,
  };

  return headerProps;
};

export const getDomain = ({
  domain,
  devEnabled = false,
  nodeEnv,
}: {
  domain?: string;
  devEnabled?: boolean;
  nodeEnv?: string;
}) => {
  if (domain) {
    if (nodeEnv === 'development' && devEnabled) {
      return `https://dev.${domain}:8443`;
    }

    return `https://www.${domain}`;
  }

  return `https://www.mercadolivre.com.br`;
};

export function getProfileName(
  headerResponse: HeaderData,
  device: string | undefined,
  profileTitle: string,
) {
  const profileWording = headerResponse.labels[0]?.label?.text;

  const profileName =
    profileWording && device === 'desktop'
      ? `${profileWording} | ${profileTitle}`
      : profileTitle;

  return profileName;
}

export const DEVICE_TYPES = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
  TABLET: 'tablet',
} as const;

export type DeviceType = (typeof DEVICE_TYPES)[keyof typeof DEVICE_TYPES];

export const OS_NAMES = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web',
} as const;

export type OsNames = (typeof OS_NAMES)[keyof typeof OS_NAMES];

export const extractEnvFromUrl = (url: string) => {
  const queryString = url.includes('?') ? url.split('?')[1] : null;

  if (!queryString) {
    return undefined;
  }

  const searchParams = new URLSearchParams(queryString);

  return searchParams.get('env');
};

export const isValidEnvValue = (env: string) => {
  // eslint-disable-next-line security/detect-unsafe-regex
  const regex = /^(?:[a-zA-Z]+(?:-[a-zA-Z0-9]+)*)$/;

  return regex.test(env);
};

export const removeEnvFromUrl = (url: string) => {
  const [path, queryString] = url.split('?');

  if (!queryString) {
    return url;
  }

  const searchParams = new URLSearchParams(queryString);

  searchParams.delete('env');

  const updatedQuery = searchParams.toString();

  return updatedQuery ? `${path}?${updatedQuery}` : path;
};

export const addEnvToUrl = (url: string) => {
  const env = extractEnvFromUrl(url);

  if (!env) {
    return url;
  }

  if (!isValidEnvValue(env)) {
    const newUrl = removeEnvFromUrl(url);

    return newUrl;
  }

  return url;
};
