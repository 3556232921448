import type { Lists } from '../../../../utils/contracts';

import {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import { useRequestListContent } from '../../../hooks/useRequestContent';

interface PaginationListState {
  isWebview: boolean;
  isListOfLists: boolean;
  listsPerPage: string;
  pageQuantity: number;
  responsesDesktop: Record<number, Array<Lists>>;
  responseMobile: Array<Lists>;
  selectedPage: number;
  totalItems: number;
  listPerPage: number;
  listPerPageMobile: number;
  loading: boolean;
  setIsWebview: (value: boolean) => void;
  setIsListOfLists: (value: boolean) => void;
  setSelectedPage: (page: number) => void;
  setPageQuantity: (quantity: number) => void;
  setTotalItems: (totalItems: number) => void;
  initPageValue: (lists: Array<Lists>) => void;
  updatePage: (page: number, listsPerPAge: number) => void;
  getListsPerPage: () => Array<Lists>;
  deleteCacheResponse: () => void;
}

const initialState: PaginationListState = {
  isWebview: false,
  isListOfLists: false,
  listsPerPage: '2',
  pageQuantity: 1,
  responsesDesktop: {},
  responseMobile: [],
  selectedPage: 1,
  totalItems: 0,
  listPerPage: 2,
  listPerPageMobile: 2,
  loading: false,
  setIsWebview: () => {},
  setIsListOfLists: () => {},
  setPageQuantity: () => {},
  setSelectedPage: () => {},
  setTotalItems: () => {},
  initPageValue: () => {},
  updatePage: () => {},
  getListsPerPage: () => [],
  deleteCacheResponse: () => {},
};

const PaginationContext = createContext<PaginationListState>({
  ...initialState,
});

interface PaginationProviderProps {
  children: React.ReactNode;
  env: string;
  lists: Array<Lists>;
  profilePath: string;
  totalListsInit: number;
  isWebviewListInint: boolean;
  isListOfListsInit: boolean;
  limit?: string;
  userId: number;
}

export const PaginationListProvider = ({
  children,
  env,
  lists,
  profilePath,
  totalListsInit,
  isWebviewListInint,
  isListOfListsInit,
  limit,
  userId,
}: PaginationProviderProps) => {
  const [loading, setLoading] = useState(initialState.loading);
  const [listsPerPage, setListsPerPage] = useState(
    limit || initialState.listsPerPage,
  );
  const [isWebview, setIsWebview] = useState(isWebviewListInint);
  const [isListOfLists, setIsListOfLists] = useState(isListOfListsInit);
  const [responsesDesktop, setResponsesDesktop] = useState(
    initialState.responsesDesktop,
  );
  const [responseMobile, setResponseMobile] = useState(
    initialState.responseMobile,
  );
  const [selectedPage, setSelectedPage] = useState(initialState.selectedPage);
  const [pageQuantity, setPageQuantity] = useState(initialState.pageQuantity);
  const [totalItems, setTotalItems] = useState(initialState.totalItems);
  const [listPerPage, setListPerPage] = useState(
    Number(limit) || initialState.listPerPage,
  );
  const [listPerPageMobile, setListPerPageMobile] = useState(
    Number(limit) || initialState.listPerPageMobile,
  );

  const initPageValue = useCallback(
    (policards: Array<Lists>) => {
      if (isWebview) {
        setResponseMobile(policards);
      } else {
        setResponsesDesktop({ 1: policards });
      }
    },
    [isWebview],
  );

  const calculateQuantityPage = useCallback(
    (totalItemsOfList: number) =>
      Math.ceil(totalItemsOfList / parseInt(listsPerPage, 10)),
    [listsPerPage],
  );

  const onError = () => {
    setLoading(false);
  };

  const onSuccess = (
    data: Array<Lists> | undefined,
    newTotalItems: number | undefined,
    page: number | undefined,
  ) => {
    if (data && page) {
      if (isWebview) {
        setResponseMobile((prev) => [
          ...prev,
          ...data.filter(
            (newItem) => !prev.some((prevItem) => prevItem.id === newItem.id),
          ),
        ]);
      } else if (newTotalItems && newTotalItems !== totalItems) {
        setPageQuantity(calculateQuantityPage(newTotalItems));
        setTotalItems(newTotalItems);
        setResponsesDesktop({
          [page]: data,
        });
      } else {
        setResponsesDesktop((prev) => ({
          ...prev,
          [page]: data,
        }));
      }

      setSelectedPage(page);
      setLoading(false);
    }
  };

  const { getPaginatedListContent } = useRequestListContent({
    onError,
    onSuccess,
  });

  const getListsPerPage = useCallback(
    () => (isWebview ? responseMobile : responsesDesktop[selectedPage]),
    [responsesDesktop, selectedPage, isWebview, responseMobile],
  );

  useEffect(() => {
    initPageValue(lists);
  }, [isWebview, lists, initPageValue]);

  useEffect(() => {
    setIsWebview(isWebviewListInint);
    setIsListOfLists(isListOfListsInit);
    setPageQuantity(calculateQuantityPage(totalListsInit));
    setTotalItems(totalListsInit);
  }, [
    isWebviewListInint,
    isListOfListsInit,
    totalListsInit,
    calculateQuantityPage,
  ]);

  const validateListItemtoTotalItems = useCallback(
    (page: number) => {
      let validate = true;

      if (
        isWebview &&
        (totalItems === Object.keys(responseMobile).length ||
          page > pageQuantity)
      ) {
        validate = false;
      }

      return validate;
    },
    [totalItems, responseMobile, isWebview, pageQuantity],
  );

  const updatePage = useCallback(
    (page: number) => {
      if (page === selectedPage) {
        return;
      }

      let cachedResponse;

      if (!isWebview) {
        cachedResponse = responsesDesktop[page];
      }

      if (cachedResponse && cachedResponse.length > 0) {
        setSelectedPage(page);
      }

      if (
        (isWebview && validateListItemtoTotalItems(page)) ||
        (!isWebview && !cachedResponse)
      ) {
        setLoading(true);
        getPaginatedListContent({
          env,
          limit: listsPerPage,
          page,
          profilePath,
          userId,
        });
      } else {
        setLoading(false);
      }
    },
    [
      env,
      getPaginatedListContent,
      isWebview,
      listsPerPage,
      profilePath,
      responsesDesktop,
      selectedPage,
      validateListItemtoTotalItems,
      userId,
    ],
  );

  const deleteCacheResponse = useCallback(() => {
    if (!isWebview) {
      setResponsesDesktop({});
    }

    setTotalItems((prevTotalItems) => prevTotalItems - 1);
  }, [isWebview]);

  const value = useMemo(
    () => ({
      isWebview,
      isListOfLists,
      listsPerPage,
      responsesDesktop,
      responseMobile,
      selectedPage,
      pageQuantity,
      totalItems,
      listPerPage,
      listPerPageMobile,
      setIsWebview,
      setIsListOfLists,
      setResponsesDesktop,
      setResponseMobile,
      setSelectedPage,
      setPageQuantity,
      setListPerPage,
      setListPerPageMobile,
      setTotalItems,
      initPageValue,
      updatePage,
      getListsPerPage,
      loading,
      setListsPerPage,
      deleteCacheResponse,
    }),
    [
      isWebview,
      isListOfLists,
      listsPerPage,
      responsesDesktop,
      responseMobile,
      selectedPage,
      pageQuantity,
      totalItems,
      listPerPage,
      listPerPageMobile,
      setIsWebview,
      setIsListOfLists,
      setResponsesDesktop,
      setResponseMobile,
      setSelectedPage,
      setPageQuantity,
      setListPerPage,
      setListPerPageMobile,
      setTotalItems,
      initPageValue,
      updatePage,
      getListsPerPage,
      loading,
      setListsPerPage,
      deleteCacheResponse,
    ],
  );

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  );
};

export const usePaginationList = () => useContext(PaginationContext);

export default { usePaginationList, PaginationListProvider };
