export const CAROUSEL_CONFIG_BY_DEVICE = {
  CAROUSEL: {
    desktop: {
      slidesPerView: 5,
      thresholdSlide: 0,
      arrows: true,
    },
    mobile: {
      slidesPerView: 2,
      thresholdSlide: 20,
      arrows: false,
    },
    tablet: {
      slidesPerView: 3,
      thresholdSlide: 20,
      arrows: false,
    },
  },
};
