import React from 'react';
import { Card, CardContent } from '@andes/card';
import { Text } from '@andes/typography';
import { Pill } from '@andes/badge';

interface DenounceProfileProps {
  label: string;
  link: string;
  url: string;
  deviceType: any;
}

export const DenounceProfile: React.FC<DenounceProfileProps> = ({
  label,
  link,
  url,
  deviceType,
}) => (
  <Card className="ui-denounce-profile">
    <CardContent className="ui-denounce-profile_content">
      <Pill color="accent" contentType="icon" size="small" />
      {deviceType === 'mobile' ? (
        <div className="ui-denounce-profile-wordings">
          <Text size="xs" color="secondary">
            {label}
          </Text>
          <Text
            size="xs"
            color="link"
            component="a"
            href={url}
            target="_blank"
            className="ui-denounce-profile-link"
          >
            {link}
          </Text>
        </div>
      ) : (
        <>
          <Text size="xs" color="secondary">
            {label}
          </Text>
          <hr className="ui-denounce-profile_content-hr" />
          <Text size="xs" color="link" component="a" href={url} target="_blank">
            {link}
          </Text>
        </>
      )}
    </CardContent>
  </Card>
);
