import type React from 'react';

import { Pagination } from '@andes/pagination';

import { usePaginationList } from '../../providers/Pagination/src/PaginationListProvider';

const PaginationListOfLists = () => {
  const { updatePage, selectedPage, pageQuantity, isWebview, listPerPage } =
    usePaginationList();

  if (isWebview || pageQuantity <= 1) {
    return null;
  }

  const handleOnChangePagination = (
    event:
      | React.KeyboardEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLAnchorElement>,
    newPage: number,
  ) => {
    event.preventDefault();
    updatePage(newPage, listPerPage);
  };

  return (
    <div className="aff-social-pagination__container">
      <Pagination
        onChange={handleOnChangePagination}
        pageQuantity={pageQuantity}
        pageSelected={selectedPage}
        href="#"
      />
    </div>
  );
};

export default PaginationListOfLists;
