import type { PolycardContractInterface } from '@polycard/card';
import type { Metrics } from '../../../../utils/contracts';

import { useEffect } from 'react';

import { Card } from '@polycard/card';
import classNames from 'classnames';

import { DEVICE_TYPES, type DeviceType } from '../../../utils/utils';
import useLoading from '../../../hooks/useLoading';

interface PolycardItemProps {
  className?: string;
  metrics: Metrics;
  polycard: PolycardContractInterface;
  polycardDeleted?: Array<string>;
  deviceType?: DeviceType;
}

const PolycardItem = ({
  className,
  metrics,
  polycard,
  polycardDeleted,
  deviceType,
}: PolycardItemProps) => {
  const { loadingComponent, showLoading } = useLoading({
    modifier: 'block',
    isCard: true,
    size: 'medium',
  });

  const replaceUrlFragment = (url: string) =>
    url.replace('%7Btracking_id%7D', metrics.tracking_id);

  const getPolicardMetadata = (card: PolycardContractInterface) => {
    const { metadata } = card;

    return {
      ...metadata,
      url_fragments: replaceUrlFragment(metadata.url_fragments!),
    };
  };

  useEffect(() => {
    if (polycardDeleted?.includes(polycard.metadata.id)) {
      showLoading(true);
    } else {
      showLoading(false);
    }
  }, [polycard, polycardDeleted, showLoading]);

  const polycardItemClass = classNames('aff-social-polycard__container', {
    'aff-social-polycard__container-mobile':
      deviceType === DEVICE_TYPES.TABLET || deviceType === DEVICE_TYPES.MOBILE,
  });

  return (
    <div className={polycardItemClass}>
      {loadingComponent}
      <Card
        polycard={{
          ...polycard,
          metadata: getPolicardMetadata(polycard),
        }}
        className={className}
      />
    </div>
  );
};

export default PolycardItem;
