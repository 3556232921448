import type { PolycardContractInterface } from '@polycard/card';
import type { Metrics } from '../../../../utils/contracts';
import type { DeviceType } from '../../../utils/utils';

import { CarouselSnapped, CarouselSnappedSlide } from '@andes/carousel-snapped';

import { CAROUSEL_CONFIG_BY_DEVICE } from '../../../../utils/configCarousel';
import PolycardItem from '../src/PolycardItem';

interface CarouselProps {
  type: 'carousel' | 'grid';
  deviceType: DeviceType;
  policards: Array<PolycardContractInterface>;
  metrics: Metrics;
  polycardDeleted?: Array<string>;
}

const Carousel = ({
  type,
  policards,
  metrics,
  deviceType,
  polycardDeleted,
}: CarouselProps) => {
  if (type !== 'carousel' || policards.length === 0) {
    return null;
  }

  const settingsCarousel = {
    ...CAROUSEL_CONFIG_BY_DEVICE.CAROUSEL[deviceType],
    className: 'home-lists__carousel',
    id: 'free-carousel',
    pagination: false,
    srLabel: 'Items de la lista',
  };

  return (
    <CarouselSnapped {...settingsCarousel}>
      {policards.map((polycard: PolycardContractInterface) => (
        <CarouselSnappedSlide key={`polycard-${polycard.metadata.id}`}>
          <PolycardItem
            polycard={polycard}
            metrics={metrics}
            className="lists-card"
            polycardDeleted={polycardDeleted}
            deviceType={deviceType}
          />
        </CarouselSnappedSlide>
      ))}
    </CarouselSnapped>
  );
};

export default Carousel;
