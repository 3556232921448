import { useState, useCallback } from 'react';

import { EmptyState, type EmptyStateProps } from '../components/EmptyState';
import { useWordings } from './useWordings';

interface EmptyResponse {
  showEmpty: (show: boolean) => void;
  emptyComponent: React.ReactNode;
  emptyComponentDefault: React.ReactNode;
}

interface EmptyProps {
  isOwner: boolean;
  isListDetail: boolean;
  urlLists: string;
}

export const useEmpty = ({
  isOwner,
  isListDetail,
  urlLists,
}: EmptyProps): EmptyResponse => {
  const {
    EMPTY_LIST_DETAIL_SUBTITLE_BUYER,
    EMPTY_LIST_DETAIL_TITLE,
    EMPTY_LIST_TITLE_OWNER,
    GO_TO_OFFERS,
    EMPTY_LIST_SUBTITLE_OWNER,
    EMPTY_LIST_TITLE_BUYER,
    GO_TO_LISTS,
  } = useWordings();

  let propsRedirect = {
    offersUrl: '/ofertas',
    goToOffers: GO_TO_OFFERS,
  };

  if (isListDetail && !isOwner) {
    propsRedirect = {
      offersUrl: urlLists,
      goToOffers: GO_TO_LISTS,
    };
  }

  const getTitle = (): string => {
    if (isListDetail) {
      return EMPTY_LIST_DETAIL_TITLE;
    }

    return isOwner ? EMPTY_LIST_TITLE_OWNER : EMPTY_LIST_TITLE_BUYER;
  };

  const getSubtitle = (): string => {
    if (isOwner) {
      return EMPTY_LIST_SUBTITLE_OWNER;
    }

    return EMPTY_LIST_DETAIL_SUBTITLE_BUYER;
  };

  const emptyState: EmptyStateProps = {
    title: getTitle(),
    subtitle: getSubtitle(),
    className: 'empty-state__list',
    image: 'empty-campaigns.svg',
    ...propsRedirect,
  };

  const [empty, setEmpty] = useState(false);

  const showEmpty = useCallback((show: boolean) => setEmpty(show), []);

  const emptyComponent = empty ? <EmptyState {...emptyState} /> : null;

  const emptyComponentDefault = <EmptyState {...emptyState} />;

  return {
    showEmpty,
    emptyComponent,
    emptyComponentDefault,
  };
};

export default useEmpty;
