import { Title, Text } from '@andes/typography';
import { Image } from 'nordic/image';

export interface EmptyStateProps {
  title: string;
  subtitle: string;
  className: string;
  image: string;
  offersUrl: string;
  goToOffers: string;
}

export const EmptyState = ({
  title,
  subtitle,
  className,
  image,
  offersUrl,
  goToOffers,
}: EmptyStateProps) => (
  <div className={`empty-state ${className}`}>
    {image && <Image height={91} width={135} src={image} alt={className} />}
    <div className="empty-state__text-content">
      <Title size="s" component="h3">
        {title}
      </Title>
      <Text className="empty-state__subtitle" size="s" component="p">
        {subtitle}
      </Text>
      <Text
        className="empty-state__offers-link"
        size="s"
        component="a"
        color="link"
        href={offersUrl}
        weight="semibold"
      >
        {goToOffers}
      </Text>
    </div>
  </div>
);
