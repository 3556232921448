import type { SiteId } from 'frontend-platform_detection';
import type { Lists, Pagination } from '../../utils/contracts';

import { RestClient } from 'nordic/restclient';

import { validateError } from '../../utils/validateError';

const restclient = RestClient({
  timeout: 5000,
  baseURL: '/social/api/lists',
});

export interface SiteInfo {
  name: string;
  default_currency_id: string;
  currencies: Array<{ id: string; symbol: string }>;
}

interface SocialProfile {
  data: Array<Lists>;
  pagination: Pagination;
}

export const API_ROUTES = {
  DELETE_PRODUCT: '/delete-product',
  GET_PAGINATED_CONTENT: '/get-paginated-content',
  GET_PAGINATED_LIST_CONTENT: '/get-paginated-list-content',
} as const;

const getRequest = async (url: string, params?: any) =>
  restclient
    .get(url, {
      params,
    })
    .then((response) => response)
    .catch((error) => {
      validateError(error, 'GET');

      throw error;
    });

const deleteRequest = async (url: string, object: any, params?: any) =>
  restclient
    .delete(url, {
      params,
      data: object,
    })
    .then((response) => response)
    .catch((error) => {
      validateError(error, 'DELETE');

      throw error;
    });

export async function getSite(siteId: SiteId) {
  return getRequest(`/sites/${siteId}`);
}

export const useRequestContent = ({
  onError,
  onSuccess,
}: {
  onError: (error?: any) => void;
  onSuccess: (data?: any, totalItems?: number, page?: number) => void;
}) => {
  const deleteProduct = ({
    elementId,
    env,
    listId,
    bookmarkId,
  }: {
    elementId: string;
    env: string;
    listId: string;
    bookmarkId?: string;
  }) => {
    const url = API_ROUTES.DELETE_PRODUCT;

    deleteRequest(url, { elementId, env, listId, bookmarkId })
      .then(() => {
        onSuccess(elementId);
      })
      .catch(() => {
        onError(elementId);
      });
  };

  const getPaginatedContent = ({
    env,
    idList,
    limit,
    page,
    profilePath,
    userId,
  }: {
    env: string;
    idList: string;
    limit: string;
    page: number;
    profilePath: string;
    userId: number;
  }) => {
    const url = API_ROUTES.GET_PAGINATED_CONTENT;

    getRequest(url, { env, idList, limit, page, profilePath, userId })
      .then((response) => {
        if (response.data) {
          const data = response.data as unknown as Lists;
          const { item } = data;

          if (item.result.polycards.length > 0) {
            onSuccess(
              data.item.result.polycards,
              data.pagination.total_results,
              page,
            );
          } else {
            onError();
          }
        }
      })
      .catch(() => {
        onError();
      });
  };

  return {
    deleteProduct,
    getPaginatedContent,
  };
};

export const useRequestListContent = ({
  onError,
  onSuccess,
}: {
  onError: (error?: any) => void;
  onSuccess: (data?: any, totalItems?: number, page?: number) => void;
}) => {
  const getPaginatedListContent = ({
    env,
    limit,
    page,
    profilePath,
    userId,
  }: {
    env: string;
    limit: string;
    page: number;
    profilePath: string;
    userId: number;
  }) => {
    const url = API_ROUTES.GET_PAGINATED_LIST_CONTENT;

    getRequest(url, { env, limit, page, profilePath, userId })
      .then((response) => {
        if (response.data) {
          const data = response.data as unknown as SocialProfile;
          const { pagination, data: lists } = data;

          if (lists.length > 0) {
            /* istanbul ignore next */
            onSuccess(lists, pagination.total_results, page);
          } else {
            onError();
          }
        }
      })
      .catch(() => {
        onError();
      });
  };

  return {
    getPaginatedListContent,
  };
};
