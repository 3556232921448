import { useState, useCallback } from 'react';

import { Loading } from '../components/Loanding';

interface LoadingResponse {
  showLoading: (show: boolean) => void;
  loadingComponent: React.ReactNode;
}

interface LoadingProps {
  modifier?: 'block' | 'fullscreen' | 'inline';
  size?: 'large' | 'medium' | 'small' | 'xlarge' | 'xsmall';
  isCard?: boolean;
}

const useLoading = ({
  modifier,
  size,
  isCard = false,
}: LoadingProps): LoadingResponse => {
  const [loading, setLoading] = useState(false);

  const showLoading = useCallback((show: boolean) => setLoading(show), []);

  const loadingComponent = loading ? (
    <Loading modifier={modifier} size={size} isCard={isCard} />
  ) : null;

  return {
    showLoading,
    loadingComponent,
  };
};

export default useLoading;
