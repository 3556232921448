import { Modal } from '@andes/modal';
import { Text } from '@andes/typography';
import classNames from 'classnames';

interface ModalComponentProps {
  actions: {
    fixed?: boolean;
    primary?: React.ReactNode;
    secondary?: React.ReactNode;
  };
  closable: boolean;
  message: string;
  onClose: () => void;
  open: boolean;
  title: string;
  type: 'desktop' | 'mobile' | 'tablet';
}

export const ModalComponent = ({
  actions,
  closable,
  message,
  onClose,
  open,
  title,
  type,
}: ModalComponentProps) => {
  const modalClass = classNames('modal-component', {
    'modal-component--mobile': type !== 'desktop',
  });

  return (
    <Modal
      actions={actions}
      closable={closable}
      open={open}
      onClose={onClose}
      title={title}
      type={type === 'desktop' ? 'small' : 'card'}
      maxWidth={type === 'desktop' ? 480 : 312}
      className={modalClass}
    >
      {message && <Text>{message}</Text>}
    </Modal>
  );
};
