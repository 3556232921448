import type { Lists, Metrics } from '../../../../utils/contracts';
import type { DeviceType, OsNames } from '../../../utils/utils';

import { Header } from '../../../components/Header';
import {
  getServerSideProps,
  setPageSettings,
} from '../../../server/hooks/lists';
import { AppProvider } from '../../../providers/AppProvider';
import { ListsComponent } from '../../../components/ListsComponent';
import { PaginationListProvider } from '../../../providers/Pagination/src/PaginationListProvider';

interface ListsProps {
  deviceType: DeviceType;
  env: string;
  headerProps: Record<string, unknown>;
  lists: Array<Lists>;
  metrics: Metrics;
  redirectBaseUrl: string;
  siteId: string;
  domain: string;
  shareLink: string;
  osName: OsNames;
  listOwnerId: string;
  totalLists: number;
  profilePath: string;
  limit?: string;
  userId: number;
  isWebview: boolean;
  isOwner: boolean;
}

const ListsOfList = ({
  deviceType,
  env,
  lists,
  headerProps,
  metrics,
  redirectBaseUrl,
  siteId,
  domain,
  shareLink,
  osName,
  listOwnerId,
  totalLists,
  profilePath,
  limit,
  userId,
  isWebview,
  isOwner,
}: ListsProps) => (
  <AppProvider envParam={env}>
    {headerProps && (
      <Header {...headerProps} device_type={deviceType} siteId={siteId} />
    )}

    <PaginationListProvider
      env={env}
      isWebviewListInint={isWebview}
      lists={lists}
      profilePath={profilePath}
      totalListsInit={totalLists}
      isListOfListsInit
      limit={limit}
      userId={userId}
    >
      <ListsComponent
        deviceType={deviceType}
        lists={lists}
        metrics={metrics}
        newUrl={redirectBaseUrl}
        domain={domain}
        shareLink={shareLink}
        osName={osName}
        listOwnerId={listOwnerId}
        isOwner={isOwner}
      />
    </PaginationListProvider>
  </AppProvider>
);

export const hydrate = 'hydrateRoot';

export { getServerSideProps, setPageSettings };

export default ListsOfList;
