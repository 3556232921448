import { useI18n } from 'nordic/i18n';

interface Wordings {
  BTN_LABEL_CANCEL: string;
  BTN_LABEL_DELETE: string;
  FAIL_COPY_LINK: string;
  SUCCESS_COPY_LINK: string;
  LABEL_LOADING: string;
  MODAL_TITLE_DELETE: string;
  MODAL_MESSAGE_DELETE: string;
  MY_RECOMMENDATIONS: string;
  NAVIGATE_TO_LIST: string;
  SHARE_LIST: string;
  SHARE_LIST_MOBILE: string;
  SNACKBAR_ERROR_DELETE: string;
  SNACKBAR_SUCCESS_DELETE: string;
  SOCIAL_MEDIA_VIDEOS: string;
  TOOLTIP_TOTAL_PRODUCTS: string;
  TOOLTIP_SHARED_CONTENT: string;
  TOOLTIP_SHARED_TITLE: string;
  FOOTER_TEXT: string;
  FOOTER_LINK: string;
  BREADCRUMB_LISTS: string;
  MY_LISTS: string;
  EMPTY_LIST_DETAIL_SUBTITLE_BUYER: string;
  EMPTY_LIST_DETAIL_TITLE: string;
  EMPTY_LIST_TITLE_OWNER: string;
  GO_TO_OFFERS: string;
  EMPTY_LIST_SUBTITLE_OWNER: string;
  EMPTY_LIST_TITLE_BUYER: string;
  GO_TO_LISTS: string;
}

export const useWordings = (): Wordings => {
  const { i18n } = useI18n();
  const wordings = {
    BTN_LABEL_CANCEL: i18n.gettext('Cancelar'),
    BTN_LABEL_DELETE: i18n.gettext('Eliminar'),
    // snackbar
    FAIL_COPY_LINK: i18n.gettext(
      'No pudimos copiar el link. Inténtalo de nuevo.',
    ),
    SUCCESS_COPY_LINK: i18n.gettext('El link fue copiado al portapapeles.'),
    LABEL_LOADING: i18n.gettext('Cargando...'),
    MODAL_TITLE_DELETE: i18n.gettext(
      'Estás por eliminar el producto de la lista',
    ),
    MODAL_MESSAGE_DELETE: i18n.gettext(
      'Si lo eliminas, tendrás que generar un nuevo link para que aparezca en esta lista.',
    ),
    MY_RECOMMENDATIONS: i18n.gettext('Mis recomendaciones'),
    NAVIGATE_TO_LIST: i18n.gettext('Ir a la lista'),
    SHARE_LIST: i18n.gettext('Compartir lista'),
    SHARE_LIST_MOBILE: i18n.gettext('Compartir'),
    SNACKBAR_DELETE_MESSAGE: i18n.gettext(
      'Eliminaste el producto de la lista.',
    ),
    SOCIAL_MEDIA_VIDEOS: i18n.gettext('Recomendados de mis videos'),
    SNACKBAR_ERROR_DELETE: i18n.gettext(
      'No pudimos eliminar el producto de la lista.',
    ),
    SNACKBAR_SUCCESS_DELETE: i18n.gettext(
      'Eliminaste el producto de la lista.',
    ),
    TOOLTIP_TOTAL_PRODUCTS: i18n.gettext(
      'Tu lista muestra los últimos 300 productos recomendados.',
    ),
    TOOLTIP_SHARED_CONTENT: i18n.gettext(
      'Haz que tu audiencia encuentre todas tus recomendaciones en un solo lugar.',
    ),
    TOOLTIP_SHARED_TITLE: i18n.gettext('Comparte y genera más ventas'),
    FOOTER_TEXT: i18n.gettext(
      'Este perfil genera ingresos por sus recomendaciones.',
    ),
    FOOTER_LINK: i18n.gettext('Denunciar'),
    BREADCRUMB_LISTS: i18n.gettext('Inicio'),
    MY_LISTS: i18n.gettext('Mis listas'),
    // empty list
    EMPTY_LIST_DETAIL_TITLE: i18n.gettext('Esta lista aún está vacía'),
    EMPTY_LIST_TITLE_BUYER: i18n.gettext('Este perfil aún está vacío'),
    EMPTY_LIST_TITLE_OWNER: i18n.gettext('Tu perfil está vacío'),
    EMPTY_LIST_DETAIL_SUBTITLE_BUYER: i18n.gettext(
      'Mientras tanto, puedes descubrir ofertas en cientos de productos.',
    ),
    EMPTY_LIST_SUBTITLE_OWNER: i18n.gettext(
      'Crea y publica listas de afiliados para recibir ingresos por tus recomendaciones.',
    ),
    GO_TO_OFFERS: i18n.gettext('Ir a Ofertas'),
    GO_TO_LISTS: i18n.gettext('Ir a otras listas'),
  };

  return wordings;
};
