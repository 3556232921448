import { LoggerFactory } from 'nordic/logger';

const logger = LoggerFactory('social-profile-view:utils:sendMelidata');

/**
 * Sends a track to melidata.
 * 'melidata' is a script that it is added into the DOM with the Nordic Pages
 *
 * @param method the method name to execute ['send' or 'cleanAndSend']
 * @param type the type of track to be sent ['event' or 'view']
 * @param path the track path to match with the melidata-catalog
 * @param data object which constains any custom data
 */
const sendMelidata = (
  action: 'cleanAndSend' | 'send',
  trackType: 'event' | 'show' | 'view' | 'watch',
  path: string,
  data: Record<string, unknown>,
) => {
  if (typeof window !== 'undefined' && typeof window.melidata !== 'undefined') {
    const { melidata } = window;

    try {
      melidata(
        action,
        trackType,
        {
          path,
          event_data: data,
        },
        undefined,
      );
    } catch (error: unknown) {
      logger.error(
        `An error has occured on scripts:sendMelidata: it was not possible to call melidata with method: '${action}', type: '${trackType}', path: '${path}' and data: '${JSON.stringify(
          data,
        )}'`,
      );
    }
  } else {
    logger.error(
      'An error has occurred on scripts:sendMelidata: melidata is not defined.',
    );
  }
};

export { sendMelidata };
