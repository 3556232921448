import type { PolycardContractInterface } from '@polycard/card';

import {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import { useRequestContent } from '../../../hooks/useRequestContent';

interface PaginationState {
  isWebview: boolean;
  isListDetail: boolean;
  itemsPerPage: string;
  pageQuantity: number;
  responsesDesktop: Record<number, Array<PolycardContractInterface>>;
  responseMobile: Array<PolycardContractInterface>;
  selectedPage: number;
  totalItems: number;
  itemPerPage: number;
  itemPerPageMobile: number;
  loading: boolean;
  setIsWebview: (value: boolean) => void;
  setIsListDetail: (value: boolean) => void;
  setSelectedPage: (page: number) => void;
  setPageQuantity: (quantity: number) => void;
  setTotalItems: (totalItems: number) => void;
  initPageValue: (policards: Array<PolycardContractInterface>) => void;
  updatePage: (page: number, itemsPerPAge: number) => void;
  getItemsPerPage: () => Array<PolycardContractInterface>;
  deleteCacheResponse: () => void;
}

const initialState: PaginationState = {
  isWebview: false,
  isListDetail: false,
  itemsPerPage: '10',
  pageQuantity: 1,
  responsesDesktop: {},
  responseMobile: [],
  selectedPage: 1,
  totalItems: 0,
  itemPerPage: 20,
  itemPerPageMobile: 10,
  loading: false,
  setIsWebview: () => {},
  setIsListDetail: () => {},
  setPageQuantity: () => {},
  setSelectedPage: () => {},
  setTotalItems: () => {},
  initPageValue: () => {},
  updatePage: () => {},
  getItemsPerPage: () => [],
  deleteCacheResponse: () => {},
};

const PaginationContext = createContext<PaginationState>({ ...initialState });

interface PaginationProviderProps {
  children: React.ReactNode;
  env: string;
  listId: string;
  polycards: Array<PolycardContractInterface>;
  profilePath: string;
  totalItemsInit: number;
  isWebviewInint: boolean;
  isListDetailInit: boolean;
  limit?: string;
  userId: number;
}

export const PaginationProvider = ({
  children,
  env,
  listId,
  polycards,
  profilePath,
  totalItemsInit,
  isWebviewInint,
  isListDetailInit,
  limit,
  userId,
}: PaginationProviderProps) => {
  const [loading, setLoading] = useState(initialState.loading);
  const [itemsPerPage, setItemsPerPage] = useState(
    limit || initialState.itemsPerPage,
  );
  const [isWebview, setIsWebview] = useState(isWebviewInint);
  const [isListDetail, setIsListDetail] = useState(isListDetailInit);
  const [responsesDesktop, setResponsesDesktop] = useState(
    initialState.responsesDesktop,
  );
  const [responseMobile, setResponseMobile] = useState(
    initialState.responseMobile,
  );
  const [selectedPage, setSelectedPage] = useState(initialState.selectedPage);
  const [pageQuantity, setPageQuantity] = useState(initialState.pageQuantity);
  const [totalItems, setTotalItems] = useState(initialState.totalItems);
  const [itemPerPage, setItemPerPage] = useState(
    Number(limit) || initialState.itemPerPage,
  );
  const [itemPerPageMobile, setItemPerPageMobile] = useState(
    Number(limit) || initialState.itemPerPageMobile,
  );

  const initPageValue = useCallback(
    (policards: Array<PolycardContractInterface>) => {
      if (isWebview) {
        setResponseMobile(policards);
      } else {
        setResponsesDesktop({ 1: policards });
      }
    },
    [isWebview],
  );

  const calculateQuantityPage = useCallback(
    (totalItemsOfList: number) =>
      Math.ceil(totalItemsOfList / parseInt(itemsPerPage, 10)),
    [itemsPerPage],
  );

  const onError = () => {
    setLoading(false);
  };

  const onSuccess = (
    data: Array<PolycardContractInterface> | undefined,
    newTotalItems: number | undefined,
    page: number | undefined,
  ) => {
    if (data && page) {
      if (isWebview) {
        setResponseMobile((prev) => [
          ...prev,
          ...data.filter(
            (newItem) =>
              !prev.some(
                (prevItem) => prevItem.metadata.id === newItem.metadata.id,
              ),
          ),
        ]);
      } else if (newTotalItems && newTotalItems !== totalItems) {
        setPageQuantity(calculateQuantityPage(newTotalItems));
        setTotalItems(newTotalItems);
        setResponsesDesktop({
          [page]: data,
        });
      } else {
        setResponsesDesktop((prev) => ({
          ...prev,
          [page]: data,
        }));
      }

      setSelectedPage(page);
      setLoading(false);
    }
  };

  const { getPaginatedContent } = useRequestContent({ onError, onSuccess });

  const getItemsPerPage = useCallback(() => {
    if (isWebview) {
      return responseMobile;
    }

    return responsesDesktop[selectedPage];
  }, [responsesDesktop, selectedPage, isWebview, responseMobile]);

  useEffect(() => {
    initPageValue(polycards);
  }, [isWebview, polycards, initPageValue]);

  useEffect(() => {
    setIsWebview(isWebviewInint);
    setIsListDetail(isListDetailInit);
    setPageQuantity(calculateQuantityPage(totalItemsInit));
    setTotalItems(totalItemsInit);
  }, [isWebviewInint, isListDetailInit, totalItemsInit, calculateQuantityPage]);

  const validateListItemtoTotalItems = useCallback(
    (page: number) => {
      let validate = true;

      if (
        isWebview &&
        (totalItems === responseMobile.length || page > pageQuantity)
      ) {
        validate = false;
      }

      return validate;
    },
    [totalItems, responseMobile, isWebview, pageQuantity],
  );

  const updatePage = useCallback(
    (page: number) => {
      if (page === selectedPage) {
        return;
      }

      let cachedResponse;

      if (!isWebview) {
        cachedResponse = responsesDesktop[page];
      }

      if (cachedResponse && cachedResponse.length > 0) {
        setSelectedPage(page);
      }

      if (
        (isWebview && validateListItemtoTotalItems(page)) ||
        (!isWebview && !cachedResponse)
      ) {
        setLoading(true);
        getPaginatedContent({
          env,
          idList: listId,
          limit: itemsPerPage,
          page,
          profilePath,
          userId,
        });
      } else {
        setLoading(false);
      }
    },
    [
      env,
      getPaginatedContent,
      isWebview,
      itemsPerPage,
      listId,
      profilePath,
      responsesDesktop,
      selectedPage,
      validateListItemtoTotalItems,
      userId,
    ],
  );

  const deleteCacheResponse = useCallback(() => {
    if (!isWebview) {
      setResponsesDesktop({});
    }

    setTotalItems((prevTotalItems) => prevTotalItems - 1);
  }, [isWebview]);

  const value = useMemo(
    () => ({
      isWebview,
      isListDetail,
      itemsPerPage,
      responsesDesktop,
      responseMobile,
      selectedPage,
      pageQuantity,
      totalItems,
      itemPerPage,
      itemPerPageMobile,
      setIsWebview,
      setIsListDetail,
      setResponsesDesktop,
      setResponseMobile,
      setSelectedPage,
      setPageQuantity,
      setItemPerPage,
      setItemPerPageMobile,
      setTotalItems,
      initPageValue,
      updatePage,
      getItemsPerPage,
      loading,
      setItemsPerPage,
      deleteCacheResponse,
    }),
    [
      isWebview,
      isListDetail,
      itemsPerPage,
      responsesDesktop,
      responseMobile,
      selectedPage,
      pageQuantity,
      totalItems,
      itemPerPage,
      itemPerPageMobile,
      setIsWebview,
      setIsListDetail,
      setResponsesDesktop,
      setResponseMobile,
      setSelectedPage,
      setPageQuantity,
      setItemPerPage,
      setItemPerPageMobile,
      setTotalItems,
      initPageValue,
      updatePage,
      getItemsPerPage,
      loading,
      setItemsPerPage,
      deleteCacheResponse,
    ],
  );

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = () => useContext(PaginationContext);

export default { usePagination, PaginationProvider };
