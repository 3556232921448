import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';
import classNames from 'classnames';

import { useWordings } from '../../../hooks/useWordings';
import { usePagination } from '../../../providers/Pagination';

export interface LoadingProps {
  modifier?: 'block' | 'fullscreen' | 'inline';
  size?: 'large' | 'medium' | 'small' | 'xlarge' | 'xsmall';
  isCard?: boolean;
}

export const Loading = ({
  modifier = 'block',
  size = 'xlarge',
  isCard = false,
}: LoadingProps) => {
  const { LABEL_LOADING } = useWordings();
  const { isWebview } = usePagination();
  const defaultclass = 'aff-social-container-loading';
  const loandingClass = classNames({
    defaultclass: !isCard,
    [`${defaultclass}-card`]: isCard,
    [`${defaultclass}-card-desktop`]: !isWebview,
    [`${defaultclass}-card-webview`]: isWebview,
  });

  return (
    <div className={loandingClass}>
      <ProgressIndicatorCircular
        modifier={modifier}
        srLabel={LABEL_LOADING}
        size={size}
      />
    </div>
  );
};
