import { LoggerFactory } from 'nordic/logger';

const logger = LoggerFactory('affiliates-stripe:api:utils:requests');

/**
 * Validates if the error message is Network Error or if the response is undefined
 * in those cases the page must be reloaded to force the user to login
 * @param error The error
 * @param method The http method GET, POST or PUT
 */
export const validateError = (error: any, method: string) => {
  if (error?.message === 'Network Error' && error?.response === undefined) {
    logger.error(`Network Error:${error}: [${method}]`);
    window.location.reload();
  }
};
