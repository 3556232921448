import type { Metrics } from '../../../utils/contracts';

import { Title } from '@andes/typography';
import { Button, ButtonText } from '@andes/button';
import Share16 from '@andes/icons/Share16';
import copy from 'copy-to-clipboard';

import {
  type DeviceType,
  type OsNames,
  DEVICE_TYPES,
  OS_NAMES,
} from '../../utils/utils';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useWordings } from '../../hooks/useWordings';
import { sendMelidata } from '../../utils/sendMelidata';
import { TrackPaths } from '../../../utils/constants';
import { copyToClipboard } from '../../../utils/scripts';

export interface MainTitleProps {
  deviceType: DeviceType;
  env: string;
  isEmpty: boolean;
  metrics: Metrics;
  osName: OsNames;
  shareLink: string;
}

export const MainTitle = ({
  deviceType,
  env,
  isEmpty,
  metrics,
  osName,
  shareLink,
}: MainTitleProps) => {
  const { SHARE_LIST_MOBILE, FAIL_COPY_LINK, SUCCESS_COPY_LINK, MY_LISTS } =
    useWordings();

  const shareLinkUrl = `${shareLink}${env ? `&env=${env}` : ''}`;
  const { showSnackbar } = useSnackbar();

  const onSuccess = () => showSnackbar(SUCCESS_COPY_LINK, 'green');
  const onError = () => showSnackbar(FAIL_COPY_LINK);
  const isDesktop = deviceType === DEVICE_TYPES.DESKTOP;

  const handleShareLink = () => {
    if (isDesktop) {
      copyToClipboard(shareLinkUrl, onSuccess, onError);
    } else if (navigator.share && osName === OS_NAMES.IOS) {
      navigator
        .share({
          title: MY_LISTS,
          url: shareLinkUrl,
        })
        .then(() => onSuccess())
        .catch((error) => {
          if (error.name === 'AbortError') {
            console.error('Error sharing: ', error);
          } else {
            onError();
            console.error('Error sharing', error);
          }
        });
    } else if (window.MobileWebKit && osName === OS_NAMES.ANDROID) {
      window.MobileWebKit.actions
        .share(MY_LISTS, shareLinkUrl)
        .then(() => onSuccess())
        .catch((error) => {
          onError();
          console.error('Error sharing', error);
        });
    } else {
      copy(shareLinkUrl);
      onSuccess();
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const {
      owner_id: ownerId,
      total_results: totalResults,
      ...restMetrics
    } = metrics;

    sendMelidata('cleanAndSend', 'event', TrackPaths.ACTIONS, {
      ...restMetrics,
      action_type: 'click',
      action_section: 'share_lists',
    });
    handleShareLink();
  };

  return (
    <div className="main-title_container">
      <Title component="h1" size={isDesktop ? 'l' : 's'}>
        {MY_LISTS}
      </Title>
      <Button
        hierarchy="transparent"
        onClick={handleSubmit}
        size="medium"
        type="button"
        disabled={isEmpty || !shareLink}
      >
        <Share16 />
        <ButtonText>{SHARE_LIST_MOBILE}</ButtonText>
      </Button>
    </div>
  );
};
