import { Snackbar } from '../components/Snackbar';

export const useSnackbar = () => {
  const showSnackbar = (message: string, color = 'red') => {
    Snackbar.show({
      message,
      color,
    });
  };

  const showSnackbarWithAction = (
    message: string,
    action: {
      text: string;
      onClick: () => void;
    },
    delay = 3000,
  ) => {
    Snackbar.show({
      actionMessage: action.text,
      delay,
      message,
      onActionClick: action.onClick,
    });
  };

  return { showSnackbar, showSnackbarWithAction };
};
