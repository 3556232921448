import { createContext, useEffect, useMemo, useState } from 'react';

type AppProvidersProps = {
  children: React.ReactNode;
  envParam: string;
};

const AppContext = createContext({ env: '' });

export const AppProvider = ({ children, envParam }: AppProvidersProps) => {
  const [env, setEnv] = useState(envParam);

  useEffect(() => {
    setEnv(envParam);
  }, [envParam]);

  const value = useMemo(() => ({ env }), [env]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppContext;
