import type { FunctionComponent, ReactNode } from 'react';
import type {
  PolycardContractContext,
  PolycardContractInterface,
} from '@polycard/card';
import type { Metrics } from '../../../utils/contracts';
import type { DeviceType } from '../../utils/utils';

import { useState, useEffect } from 'react';

import { Button } from '@andes/button';
import { PolycardContext } from '@polycard/card';
import { Icons, getIcons } from '@polycard/icons';
import isEqual from 'lodash/isEqual';

import { ModalComponent } from '../ModalComponent/ModalComponent';
import { getCardType } from '../../utils/utils';
import Carousel from './types/Carousel';
import Grid from './types/Grid';
import { usePagination } from '../../providers/Pagination/src/PaginationProvider';
import { useWordings } from '../../hooks/useWordings';
import useLoading from '../../hooks/useLoading';
import { useDeleteItem } from '../../hooks/useDeleteItem';

export type GenericPolycardProps = {
  key?: string;
  deviceType: DeviceType;
  listId: string;
  metrics: Metrics;
  polycardContext: PolycardContractContext;
  polycards: Array<PolycardContractInterface>;
  children?: ReactNode;
  type: 'carousel' | 'grid';
  isOwner?: boolean;
};

export const GenericPolycard: FunctionComponent<GenericPolycardProps> = (
  props,
) => {
  const {
    children,
    deviceType,
    key,
    listId,
    metrics,
    polycardContext,
    type,
    polycards,
    isOwner,
  } = props;

  const {
    BTN_LABEL_CANCEL,
    BTN_LABEL_DELETE,
    MODAL_TITLE_DELETE,
    MODAL_MESSAGE_DELETE,
  } = useWordings();

  const {
    responsesDesktop,
    responseMobile,
    getItemsPerPage,
    selectedPage,
    isListDetail,
    loading,
  } = usePagination();

  const [polycardsState, setPolycardsState] = useState(polycards);
  const [open, setOpen] = useState(false);
  const [elementType, setElementType] = useState('');
  const { loadingComponent, showLoading } = useLoading({
    modifier: 'fullscreen',
  });

  const polycardType = getCardType(deviceType, type);

  const {
    deleteProductList,
    removeItemFromDeleted,
    itemToRemove,
    setItemToRemove,
    handleDelete,
    polycardDeleted,
  } = useDeleteItem({
    setPolycardsState,
    listId,
    polycardsState,
    setOpen,
    elementType,
    setElementType,
  });

  const onConfirmModal = () => {
    deleteProductList(itemToRemove);
    setOpen(false);
  };

  const onCloseModal = () => {
    if (itemToRemove) {
      removeItemFromDeleted(itemToRemove.metadata.id);
    }

    setOpen(false);
    setItemToRemove(undefined);
  };

  useEffect(() => {
    if (isListDetail) {
      const itemsPerPage = getItemsPerPage();

      setPolycardsState((prevState) => {
        if (prevState && itemsPerPage && !isEqual(prevState, itemsPerPage)) {
          return itemsPerPage;
        }

        return prevState;
      });

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [
    responsesDesktop,
    getItemsPerPage,
    selectedPage,
    isListDetail,
    responseMobile,
  ]);

  useEffect(() => {
    showLoading(loading);
  }, [loading, showLoading]);

  if (polycardsState === null || polycardsState === undefined) {
    return null;
  }

  return (
    <>
      <div
        className={
          type === 'grid'
            ? 'aff-social-lists__container'
            : 'aff-social-lists__container-carousel'
        }
      >
        {loadingComponent}
        <Icons icons={getIcons()} />
        <PolycardContext
          actionCallbacks={
            isOwner ? [{ id: 'delete_action', callback: handleDelete }] : []
          }
          contextValue={{
            ...polycardContext,
            type: polycardType,
          }}
          decimalSeparator={polycardContext.decimal_separator}
          deviceType={deviceType}
          key={key}
          thousandSeparator={polycardContext.thousand_separator}
        >
          {children && children}
          <Carousel
            deviceType={deviceType}
            metrics={metrics}
            policards={polycardsState}
            type={type}
            polycardDeleted={polycardDeleted}
          />
          <Grid
            metrics={metrics}
            policards={polycardsState}
            type={type}
            polycardDeleted={polycardDeleted}
            deviceType={deviceType}
          />
        </PolycardContext>
      </div>
      <ModalComponent
        actions={{
          fixed: false,
          primary: <Button onClick={onConfirmModal}>{BTN_LABEL_DELETE}</Button>,
          secondary: (
            <Button hierarchy="transparent" onClick={onCloseModal}>
              {BTN_LABEL_CANCEL}
            </Button>
          ),
        }}
        closable={false}
        message={MODAL_MESSAGE_DELETE}
        onClose={() => {}}
        open={open}
        title={MODAL_TITLE_DELETE}
        type={deviceType}
      />
    </>
  );
};
