import { LoggerFactory } from 'nordic/logger';

const logger = LoggerFactory('demo');

export const execCommandCopy = (textToCopy: string) => {
  // deprecated functionality but it might work in older browsers
  const textArea = document.createElement('textarea');

  textArea.value = textToCopy;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');

    if (successful) {
      return true;
    }

    return false;
  } catch (error) {
    logger.error(
      `An error has occurred on scripts:execCommandCopy: not possible to copy to clipboard. Error: ${
        error as string
      }`,
    );

    return false;
  } finally {
    document.body.removeChild(textArea);
  }
};

/**
 * Copy the given text to the clipboard
 * @param textToCopy string that is going to be copied to the clipboard
 * @param onSuccess function to be executed if textToCopy was successfully copied
 * @param onError function to be executed if textToCopy was not successfully copied
 * @returns void
 */
/* istanbul ignore next */
export const copyToClipboard = (
  textToCopy: any,
  onSuccess: any,
  onError: any,
) => {
  try {
    navigator.permissions
      .query({ name: 'clipboard-write' as PermissionName })
      .then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.clipboard
            .writeText(textToCopy)
            .then(
              () => {
                onSuccess();
              },
              () => {
                onError();
              },
            )
            .catch((error) => {
              throw error;
            });
        } else {
          const commandExecuted = execCommandCopy(textToCopy);

          if (commandExecuted) {
            onSuccess();
          } else {
            onError();
          }
        }
      })
      .catch(() => {
        // Controlling exception when browser is not compatible with
        // permission name 'clipboard-write'
        // No need to log in this scenario to avoid trash data as this might
        // occur very often. For example: when using Firefox browser
        const commandExecuted = execCommandCopy(textToCopy);

        if (commandExecuted) {
          onSuccess();
        } else {
          onError();
        }
      });
  } catch (exception) {
    onError();
  }
};
