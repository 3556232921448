export const UserType = {
  AFFILIATE: 'affiliate',
  BUYER: 'buyer',
  SELLER: 'seller',
} as const;

export const TrackPaths = {
  ACTIONS: '/affiliates/lists/actions',
  LIST: '/affiliates/list',
  LISTS: '/affiliates/lists',
  DELETE: '/affiliates/list/delete_element',
} as const;

export const ITEMS_PER_PAGE = {
  MOBILE: '6',
  DESKTOP: '12',
};

export const LISTS_PER_PAGE = {
  MOBILE: '2',
  DESKTOP: '2',
};

export const VALIDATION_ERROR = 'Validation error';

export const ENV_PROD = 'prod';

export const PRIVACY_OF_LIST = {
  PRIVATE: 'private',
  PUBLIC: 'public',
} as const;
